// extracted by mini-css-extract-plugin
export var Customers = "services-module--Customers--+F16-";
export var Customers_customer = "services-module--Customers_customer--QTNdc";
export var Customers_title = "services-module--Customers_title--e9mah";
export var HeaderSection_anchors = "services-module--HeaderSection_anchors--70NmA";
export var Project = "services-module--Project--DUXYY";
export var Project_bg = "services-module--Project_bg--1-72g";
export var Project_description = "services-module--Project_description--IBWec";
export var Project_img = "services-module--Project_img--eKxXh";
export var Project_info = "services-module--Project_info--sfRUk";
export var Project_link = "services-module--Project_link--jKXr3";
export var Project_logo = "services-module--Project_logo--7U2dz";
export var Project_title = "services-module--Project_title--V5j6n";
export var Projects = "services-module--Projects--QYBsZ";
export var Service = "services-module--Service--qaIbb";
export var ServiceImg = "services-module--ServiceImg--5qe4Z";
export var Service_description = "services-module--Service_description--OwZdH";
export var Service_intro = "services-module--Service_intro--hyYRs";
export var Service_title = "services-module--Service_title--5tpEQ";
export var Slider = "services-module--Slider--Cbca+";
export var Slider_wrapper = "services-module--Slider_wrapper--np3WW";