import * as React from "react";
import * as style from "./styles/services.module.scss";
import { graphql } from "gatsby";
import uuid from "react-uuid";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useMediaQuery } from "react-responsive";
import { mobileBreakpoint } from "components/Mediaqueries";
import PageWrapper from "components/PageWrapper";
import HeaderSection from "components/HeaderSection/";
import BoundedSection from "components/BoundedSection";
import IntroSection from "components/IntroSection";
import Cta from "components/Cta";
import MorphingScrollImg from "components/MorphingScrollImg";
import MutableCursorArea from "components/MutableCursorArea";

import EmojiFlagsTwoToneIcon from "@mui/icons-material/EmojiFlagsTwoTone";
import IntegrationInstructionsTwoToneIcon from "@mui/icons-material/IntegrationInstructionsTwoTone";
import RocketIconTwoTone from "@mui/icons-material/RocketTwoTone";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";

import InditexLogo from "../images/customer_logos/inditex.svg";
import UDCLogo from "../images/customer_logos/udc.svg";
import FridamaLogo from "../images/customer_logos/fridama.svg";
import ElogiaLogo from "../images/customer_logos/elogia.png";
import XuntaLogo from "../images/customer_logos/xunta.svg";
import DenodoLogo from "../images/customer_logos/denodo.png";
import EnxendraLogo from "../images/customer_logos/enxendra.png";

import YepcodeLogo from "../images/our_products/logos/yepcode.svg";
import TracktheraceLogo from "../images/our_products/logos/tracktherace.svg";
import BalaenaToolkitLogo from "../images/our_products/logos/balaena_toolkit.svg";
import EquipzillaLogo from "../images/our_products/logos/equipzilla.svg";
import EqusLogo from "../images/our_products/logos/equs.svg";
import AdquioLogo from "../images/our_products/logos/adquio.svg";

const Header = ({ t }) => (
  <HeaderSection
    preTitle={t("services.header.preTitle")}
    title={<Trans i18nKey="services.header.title" />}
    description={
      <ul className={style.HeaderSection_anchors}>
        <li>
          <span>
            <IntegrationInstructionsTwoToneIcon />
          </span>
          {t("services.header.description.consulting")}
        </li>
        <li>
          <span>
            <EmojiFlagsTwoToneIcon />
          </span>
          {t("services.header.description.projects")}
        </li>
      </ul>
    }
  />
);

const slides = [
  {
    img: (data) => data && getImage(data.slider1),
    alt: "",
  },
  {
    img: (data) => data && getImage(data.slider2),
    alt: "",
  },
];

const Slider = ({ data }) => {
  const isMobile = useMediaQuery(mobileBreakpoint);
  return (
    <BoundedSection
      className={style.Slider}
      boundedClassName={style.Slider_wrapper}
    >
      <Swiper
        enabled={isMobile}
        slidesPerView={isMobile ? 1.25 : 2}
        spaceBetween={isMobile ? 16 : 32}
        slidesPerGroup={isMobile ? 1 : 2}
        loop={true}
        loopFillGroupWithBlank={true}
        className="mySwiper"
      >
        {slides.map(({ img, alt }) => (
          <SwiperSlide key={uuid()}>
            <MorphingScrollImg img={img(data)} alt={alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </BoundedSection>
  );
};

const Intro = () => (
  <IntroSection>
    <p>
      <Trans i18nKey="services.intro" />
    </p>
  </IntroSection>
);

const Service = ({ icon, title, description, children }) => {
  return (
    <BoundedSection className={style.Service}>
      <div className={style.Service_intro}>
        <div>
          <h3 className={style.Service_title}>
            {icon}
            {title}
          </h3>
          <div className={style.Service_description}>{description}</div>
        </div>
      </div>
      {children}
    </BoundedSection>
  );
};

const ConsultingService = ({ t }) => (
  <Service
    icon={<IntegrationInstructionsTwoToneIcon />}
    title={t("services.consultingService.title")}
    description={<Trans i18nKey="services.consultingService.description" />}
  >
    <Customers t={t} />
  </Service>
);

const customerLogos = [
  { title: "UDC", img: UDCLogo, width: "100%" },
  { title: "Inditex", img: InditexLogo, width: "65%" },
  { title: "Denodo", img: DenodoLogo, width: "80%" },
  { title: "Enxendra", img: EnxendraLogo, width: "100%" },
  { title: "Fridama", img: FridamaLogo, width: "90%" },
  { title: "Xunta", img: XuntaLogo, width: "90%" },
  { title: "Elogia", img: ElogiaLogo, width: "45%" },
];

const Customers = ({ t }) => {
  const isMobile = useMediaQuery(mobileBreakpoint);
  return (
    <div className={style.Customers}>
      <h3 className={style.Customers_title}>{t("services.customers.title")}</h3>
      <Swiper
        slidesPerView={isMobile ? 2 : 5}
        slidesPerGroup={isMobile ? 2 : 1}
        spaceBetween={isMobile ? 16 : 24}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        {customerLogos.map(({ title, img, width }) => (
          <SwiperSlide key={uuid()}>
            <div className={style.Customers_customer}>
              <img src={img} alt={title} width={width} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

const Project = ({ t, bgImg, logo, logoWidth, title, description, link }) => {
  return (
    <div className={style.Project}>
      <a
        className={style.Project_img}
        href={link}
        data-cursor-size="120px"
        data-cursor-color="#081832"
        data-cursor-text={t("services.project.link")}
      >
        <GatsbyImage className={style.Project_bg} image={bgImg} alt={title} />
        <motion.img
          className={style.Project_logo}
          src={logo}
          alt={title}
          width={logoWidth}
          initial={{ filter: "grayscale(1) drop-shadow(2px 4px 6px white)" }}
          whileInView={{
            filter: "grayscale(0) drop-shadow(2px 4px 6px white)",
          }}
          transition={{ delay: 0.5, duration: 0.5 }}
        />
      </a>
      <div className={style.Project_info}>
        <h4 className={style.Project_title}>{title}</h4>
        <p className={style.Project_description}>{description}</p>
        <MutableCursorArea
          className={style.Project_link}
          Tag="a"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("services.project.link")}
          <LaunchTwoToneIcon />
        </MutableCursorArea>
      </div>
    </div>
  );
};

const projectsData = [
  {
    title: "services.project.yepcode.title",
    logo: YepcodeLogo,
    logoWidth: "38%",
    bgImg: (data) => data && getImage(data.yepcodeBg),
    description: "services.project.yepcode.description",
    link: "https://yepcode.io/",
  },
  {
    title: "services.project.tracktherace.title",
    logo: TracktheraceLogo,
    logoWidth: "32%",
    bgImg: (data) => data && getImage(data.tracktheraceBg),
    description: "services.project.tracktherace.description",
    link: "https://tracktherace.com/",
  },
  {
    title: "services.project.balaenatoolkit.title",
    logo: BalaenaToolkitLogo,
    logoWidth: "38%",
    bgImg: (data) => data && getImage(data.balaenaToolkitBg),
    description: "services.project.balaenatoolkit.description",
    link: "https://balaenatoolkit.com/",
  },
  {
    title: "services.project.equipzilla.title",
    logo: EquipzillaLogo,
    logoWidth: "33%",
    bgImg: (data) => data && getImage(data.equipzillaBg),
    description: "services.project.equipzilla.description",
    link: "https://equipzilla.com/",
  },
  {
    title: "services.project.equs.title",
    logo: EqusLogo,
    logoWidth: "28%",
    bgImg: (data) => data && getImage(data.equsBg),
    description: "services.project.equs.description",
    link: "https://equs-app.com/",
  },
  {
    title: "services.project.adquio.title",
    logo: AdquioLogo,
    logoWidth: "35%",
    bgImg: (data) => data && getImage(data.adquioBg),
    description: "services.project.adquio.description",
    link: "https://adquio.com/",
  },
];

const Projects = ({ t, data }) => {
  return (
    <Service
      icon={<EmojiFlagsTwoToneIcon />}
      title={t("services.projects.title")}
      description={<Trans i18nKey="services.projects.description" />}
    >
      <div className={style.Projects}>
        {projectsData.map(
          ({ title, logo, logoWidth, bgImg, description, link }) => (
            <Project
              key={uuid()}
              t={t}
              logo={logo}
              logoWidth={logoWidth}
              bgImg={bgImg(data)}
              title={t(title)}
              description={t(description)}
              link={link}
            />
          )
        )}
      </div>
    </Service>
  );
};

const CtaStartProject = ({ t }) => (
  <Cta
    isSticky
    title={t("services.cta.title")}
    description={t("services.cta.description")}
    btn={<RocketIconTwoTone />}
    btnLink="/contact"
    stickyTitle={<Trans i18nKey="services.cta.stickyTitle" />}
  />
);

const ServicesPage = ({ data }) => {
  const { t } = useTranslation();
  return (
    <PageWrapper
      seoTitle={t("services.seo.title")}
      seoDescription={t("services.seo.description")}
    >
      <Header t={t} />
      <Slider data={data} />
      <Intro />
      <ConsultingService t={t} />
      <Projects t={t} data={data} />
      <CtaStartProject t={t} />
    </PageWrapper>
  );
};

export default ServicesPage;

export const data = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    slider1: file(relativePath: { eq: "misc/mmuino_plants.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    slider2: file(relativePath: { eq: "misc/fpena_yepcode.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    yepcodeBg: file(relativePath: { eq: "our_products/bgs/yepcode_bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          transformOptions: { grayscale: true }
          placeholder: NONE
        )
      }
    }
    tracktheraceBg: file(
      relativePath: { eq: "our_products/bgs/tracktherace_bg.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          transformOptions: { grayscale: true }
          placeholder: NONE
        )
      }
    }
    balaenaToolkitBg: file(
      relativePath: { eq: "our_products/bgs/balaenatoolkit_bg.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          transformOptions: { grayscale: true }
          placeholder: NONE
        )
      }
    }
    equipzillaBg: file(
      relativePath: { eq: "our_products/bgs/equipzilla_bg.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          transformOptions: { grayscale: true }
          placeholder: NONE
        )
      }
    }
    equsBg: file(relativePath: { eq: "our_products/bgs/equs_bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          transformOptions: { grayscale: true }
          placeholder: NONE
        )
      }
    }
    adquioBg: file(relativePath: { eq: "our_products/bgs/adquio_bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          transformOptions: { grayscale: true }
          placeholder: NONE
        )
      }
    }
  }
`;
